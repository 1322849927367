import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import {
  Stripe,
  PaymentMethod as IPaymentMethod,
  PaymentMethodResult as IPaymentMethodResult,
  StripeError as IStripeError,
  PaymentMethod,
} from '@stripe/stripe-js';
import {
  CreateCart,
  addItemToCart,
  getCart,
  makeAnOrder,
  putCartCustomer,
  payOrder,
  getCustomerSchema,
  setMusementActivityStatus,
  logPurchaseInBridgify,
} from '../api/musement';
import { useBookingTicketsContext } from '../BookingTickets/useBookingTickets';

interface IProps {
  activity: IActivity_Converted;
}
export default function usePaymentDialog({ activity }: IProps): IUsePaymentDialog {
  const { uuid, requiresConfirmation } = activity;

  const {
    state: { selectedPickup, products, language, selectedDate, timeSlot, selectedGroup },
    totalPaymentValue,
  } = useBookingTicketsContext();
  const stripe = useStripe();
  const elements = useElements();

  const [error, setError] = useState<IStripeErrors>(null);
  const [cardComplete, setCardComplete] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState<IPaymentMethod | null>(null);
  const [billingDetails, setBillingDetails] = useState<IBillingDetails>({
    // email: "test@test.com",
    // firstname: "TEST",
    // lastname: "TEST",
    email: '',
    firstname: '',
    lastname: '',
  });
  const [completedProcess, setCompletedProcess] = useState<boolean>(false);
  const [progressValue, setProgressValue] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [extraFields, setExtraFields] =
    useState<{ [key in TExtraCustomerFields]: ISchema_Property }>();
  const [cartUUID, setCardUUID] = useState<string>();
  let pickup: IPickupDetails | undefined = undefined;

  if (selectedPickup) {
    const { latitude: lat, longitude: lng, uuid, name } = selectedPickup;
    pickup = { name, uuid, lng, lat };
  }

  useEffect(() => {
    async function initPaymentDialog() {
      try {
        //1. Create a card
        const createdCartUUID: string = await CreateCart();
        //2. add items to cart
        await addProductsToCart(createdCartUUID);
        //OPTIONAL--> 3 validate cart is ok
        await getCart(createdCartUUID);

        //OPTIONAL--> 4 To get all information required for your cart
        const cartCustomerSchema: ISchema = await getCustomerSchema(createdCartUUID);
        //@ts-ignore
        const Overlay_extraCustomerField_ID: TExtraCustomerFields | undefined =
          //@ts-ignore
          cartCustomerSchema?.properties['extra_customer_data']?.required[0];
        //@ts-ignore
        const requiredCustomerFields: ISchema_Property | undefined =
          Overlay_extraCustomerField_ID
            ? //@ts-ignore
              cartCustomerSchema?.properties['extra_customer_data']?.properties[
                Overlay_extraCustomerField_ID
              ]
            : undefined;
        if (requiredCustomerFields) {
          const { properties } = requiredCustomerFields;
          //Create a map of all required properties
          // const propertiesMap = required?.map((field: string) => {
          //   return properties ? (properties[field]) : null
          // })
          // update the state if there's any need
          // if (propertiesMap !== undefined && propertiesMap?.length !== 0) {
          //@ts-ignore
          setExtraFields(properties);
        }
        setCardUUID(createdCartUUID);
        setLoading(false);
      } catch (error) {
        const errorObj: IStripeError = {
          type: 'api_connection_error',
          message: 'OOPS! We had trouble creating your order, please try again later!',
        };
        setError(errorObj);
      }
    }
    initPaymentDialog();
  }, []);

  function resetFields() {
    setError(null);
    setProcessing(false);
    setPaymentMethod(null);
    setBillingDetails({
      email: '',
      firstname: '',
      lastname: '',
    });
  }

  interface IAddProduct {
    type: TProductType;
    product_identifier: string;
    quantity: string;
    pickup?: string; // pickup uuid
    language?: ILanguage['code']; // language code
  }

  async function addProductsToCart(createdCartUUID: string) {
    await Promise.all(
      products.map(async (product: IProduct) => {
        const { amount = 0, product_id, type } = product;
        if (amount > 0) {
          const productDetails: IAddProduct = {
            type,
            product_identifier: product_id,
            quantity: `${amount}`,
            pickup: pickup ? pickup.uuid : undefined,
            language: language?.code,
          };
          await addItemToCart(createdCartUUID, productDetails);
        }
      }),
    );
  }

  const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  async function submitMusementOrder(paymentDetails: PaymentMethod): Promise<void> {
    try {
      //1. Create a card
      // const createdCartUUID: string = await CreateCart();
      // setProgressValue(10)
      // //2. add items to cart
      // await addProductsToCart(createdCartUUID)
      // setProgressValue(40)
      // //OPTIONAL--> 3 validate cart is ok
      // const cartStatus: ICart = await getCart(createdCartUUID)
      // setProgressValue(50)

      // //OPTIONAL--> 4 To get all information required for your cart
      // const cartCustomerSchema = await getCustomerSchema(createdCartUUID)
      // setProgressValue(60)

      //OPTIONAL--> 4.5 assign customer to the cart
      setProgressValue(10);
      if (!cartUUID) return;
      await putCartCustomer(cartUUID, billingDetails);
      setProgressValue(30);

      //5. make an order
      const order: IOrder = await makeAnOrder(cartUUID);
      setProgressValue(70);

      const { uuid: order_uuid } = order;
      // 6. pay order through musement API
      const payOrderBody: IMusementSubmitOrder = {
        order_uuid,
        stripe_token: paymentDetails.id,
      };
      await payOrder(payOrderBody);
      setProgressValue(99);
      await delay(500);

      setProgressValue(100);

      setProcessing(false);
      setCompletedProcess(true);

      const activityStatus: 'PENDING' | 'BOOKED' = requiresConfirmation
        ? 'PENDING'
        : 'BOOKED';
      await setMusementActivityStatus({
        client: 'expenture',
        item_id: 0,
        order_id: order_uuid,
        status: activityStatus,
        pickup,
        activity_datetime: `${selectedDate?.toISOString().split('T')[0]} ${timeSlot?.time}`,
        // trip_id: localStorage.getItem('trip_id') || '',
      });

      const totalPrice = totalPaymentValue();
      // await logPurchase({
      //   order_id: order_uuid,
      //   external_id: uuid,
      //   name: selectedGroup?.name,
      //   price: totalPrice.totalSum.retail_price
      // })
      await logPurchaseInBridgify({
        trip_id: localStorage.getItem('trip_id'),
        order_id: order_uuid,
        external_id: uuid,
        name: selectedGroup?.name,
        price: totalPrice.totalSum.retail_price,
        source: 'Musement', //TODO-- findout how to identigy the source
      });

      // FOR TESTS ==> https://api-docs.musement.com/docs/test-purchases
    } catch (error) {
      // throw new Error('OOPS! payment has failed, please try again later!');
      const errorObj: IStripeError = {
        type: 'api_error',
        message: 'OOPS! payment has failed, please try again later!',
      };
      setError(errorObj);
      setProcessing(false);
      setPaymentMethod(null);
      // setMusementOrderError("OOPS! payment has failed, please try again later!")
    }
  }

  async function handleSubmit(e: any) {
    e.preventDefault();

    // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded
    if (!stripe || !elements) return;

    if (error) {
      elements?.getElement('card')?.focus();
      return;
    }

    if (cardComplete) {
      setProcessing(true);
    }

    const payload: IPaymentMethodResult = await stripe.createPaymentMethod({
      type: 'card',
      //@ts-ignore
      card: elements?.getElement(CardElement),
      billing_details: {
        name: billingDetails.firstname,
        email: billingDetails.email,
      },
    });

    // setProcessing(false);
    if (payload.error) {
      setError(payload.error);
    } else {
      setPaymentMethod(payload.paymentMethod);
      submitMusementOrder(payload.paymentMethod);
    }
  }

  function setBillingDetailsDynamic(e: any, field: string) {
    setBillingDetails({ ...billingDetails, [field]: e.target.value });
  }

  function setExtraBillingDetailsDynamic(e: any, field: string) {
    if (billingDetails.extra_customer_data) {
      setBillingDetails({
        ...billingDetails,
        extra_customer_data: {
          ...billingDetails.extra_customer_data,
          [uuid]: {
            ...billingDetails.extra_customer_data[uuid],
            [field]: e.target.value,
          },
        },
      });
    } else {
      setBillingDetails({
        ...billingDetails,
        extra_customer_data: {
          [uuid]: {
            [field]: e.target.value,
          },
        },
      });
    }
  }
  return {
    handleSubmit,
    resetFields,
    paymentMethod,
    billingDetails,
    setBillingDetails,
    setCardComplete,
    error,
    setError,
    processing,
    stripe,
    setBillingDetailsDynamic,
    progressValue,
    completedProcess,
    setCompletedProcess,
    loading,
    setLoading,
    extraFields,
    setExtraBillingDetailsDynamic,
  };
}

type IStripeErrors =
  | IStripeError
  | undefined
  | null
  | {
    type: 'validation_error';
    code: string;
    message: string;
  };

interface IMusementSubmitOrder {
  order_uuid: string;
  stripe_token: string;
}
interface IUsePaymentDialog {
  handleSubmit: (event: any) => Promise<void>;
  resetFields: () => void;
  paymentMethod: IPaymentMethod | null;
  billingDetails: IBillingDetails;
  setBillingDetails: Dispatch<SetStateAction<IBillingDetails>>;
  setCardComplete: Dispatch<SetStateAction<boolean>>;
  setError: Dispatch<SetStateAction<IStripeErrors>>;
  error: IStripeErrors;
  processing: boolean;
  stripe: Stripe | null;
  setBillingDetailsDynamic: (e: any, field: string) => void;
  setExtraBillingDetailsDynamic: (e: any, field: string) => void;
  progressValue: number;
  completedProcess: boolean;
  setCompletedProcess: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  extraFields: { [key in TExtraCustomerFields]: ISchema_Property } | undefined;
}
