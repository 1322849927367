import React from "react";
import { Button, TextField } from "@material-ui/core";
import styled from "styled-components";
import { useBookingTicketsContext } from "./useBookingTickets";
import DatePicker from "@material-ui/lab/DatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";

// type TActions = "LOADING" | "ERROR" | "DATA";
// interface IAction {
//   type: TActions;
//   payload: any;
// }
// const initialState = {
//   loading: false,
//   error: false,
// };
// type IState = typeof initialState & {
//   data?: IDate[];
// };
// interface IProps {
//   activity: IActivity_Converted;
// }
export default function Dates() {
  // { activity: { uuid } }: IProps
  const {
    handleNext,
    state: { selectedDate },
    dispatch,
  } = useBookingTicketsContext();

  function handleSelectDate(date: Date): void {
    dispatch({ type: "SELECTED_DATE", payload: date });
  }
  const today = new Date();

  return (
    // !error ? (
    // !loading ? (
    <Container>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          // value={ new Date()}
          value={(selectedDate && new Date(selectedDate)) || today}
          onChange={handleSelectDate}
          minDate={today}
          renderInput={(props: any) => (
            <TextField
              {...props}
              // label="Date" helperText="Something"
            />
          )}
        />
      </LocalizationProvider>
      {/* 
      <TextField
        id="date"
        type="date"
        onChange={(e) => {
          handleSelectDate(e.target.value);
        }}
        value={state?.selectedDate}
        // value={state?.selectedDate && Date.parse(state?.selectedDate)}
        InputLabelProps={{
          shrink: true,
        }}
      /> */}
      {/* // handleSelectDate(e.target.value) */}
      <StyleButton
        onClick={handleNext}
        variant="contained"
        color="primary"
        disabled={selectedDate === undefined}
      >
        Continue
      </StyleButton>
    </Container>
  );
  //    : (
  //     <LinearProgress />
  //   )
  // ) : (
  //   <ErrorAlert>Sorry, all dates are already sold out</ErrorAlert>
  // ));
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin: auto;
`;
const StyleButton = styled(Button)`
  && {
    margin-top: 1rem;
  }
`;

// const StyledButtonDate = styled(Button)`
//   && {
//     background-color: white;
//     border: black 1px solid;
//     width: auto;
//     margin: 1vh;
//     font-size: 1.5vh;
//     color: black;
//   }
// `;

// const SoldOutLabel = styled.label`
//   position: absolute;
//   background-color: #fe4042;
//   border-radius: 5px;
//   transform: rotateY(0deg) rotate(20deg);
//   width: 100%;
//   color: white;
//   opacity: 0.8;
// `;
// function reducer(state: IState, action: IAction): IState {
//   const { payload, type } = action;
//   switch (type) {
//     case 'DATA':
//       return { ...state, data: payload };
//     case 'ERROR':
//       return { ...state, error: payload };
//     case 'LOADING':
//       return { ...state, loading: payload };
//     default:
//       return { ...state };
//   }
// }
// const [{ error, loading, data }, dispatch] = useReducer(reducer, initialState);

// const [selectedDate, setSelectedDate] = React.useState<Date | null>(
//   new Date('2014-08-18T21:11:54'),
// );

// const handleDateChange = (date: Date | null) => {
//   setSelectedDate(date);
// };

// const [error, setError] = useState<string | null>(null)
// const [loading, setLoading] = useState(true)

// useEffect(() => {
//   async function initDates() {
//     try {
//       if (!uuid) throw new Error('No uuid');
//       // const dates: IDate[] = await getDatesByActivityID(uuid, start_date, finish_date, selectedPickup?.uuid)
//       // dispatch({ type: "DATA", payload: dates })
//       // providerDispatch({ type: "DATES_LIST", payload: dates })
//       // setLoading(false)
//     } catch (error) {
//       dispatch({ type: 'ERROR', payload: true });
//     } finally {
//       dispatch({ type: 'LOADING', payload: false });
//     }
//   }
//   initDates();
// }, []);

/* {data?.map((date, index) => {
          const { day, sold_out = false } = date
          const dayWithName = new Date(day)
          const dateString = dayWithName.toDateString()
          const shorthen = dateString.slice(0, dateString.length - 4)
          return (
            <StyledButton
              disabled={sold_out}
              key={index + day}
              value="day"
              onClick={() => handleSelectDate(date)}
              variant="contained"
            >
              {sold_out && <SoldOutLabel >SOLD OUT</SoldOutLabel>}
              (Day {index + 1}) <br />
              {shorthen.substring(0, 3) + ", " + shorthen.substring(4, shorthen.length)}
            </StyledButton >
          )
        })} */
