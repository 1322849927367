import { Alert } from '@material-ui/lab';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';

export default function ErrorAlert({
  children,
  style,
}: {
  children: any;
  style?: CSSProperties;
}) {
  return (
    <StyledErrorAlert severity="error" style={style}>
      {children}
    </StyledErrorAlert>
  );
}

const StyledErrorAlert = styled(Alert)`
  && {
    align-items: center;
    align-content: center;
    font-size: 0.9rem;
    width: fit-content;
    padding: 0px 1rem;
    font-weight: 500;
    border-radius: 5px;
  }
`;
