import React from "react";
import { Button, CircularProgress } from "@material-ui/core";
import styled from "styled-components";

export const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
}: any) => (
  <FormRow>
    <FormRowLabel htmlFor={id}>{label}</FormRowLabel>
    <FormRowInput
      id={id}
      type={type}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
    />
  </FormRow>
);
const FormRow = styled.div`
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid lightgray;

  :first-child {
    border-top: none;
  }
`;

const FormRowInput = styled.input`
  margin-left: 1em;
  font-size: 1.1em;
  width: 100%;
  padding: 11px 15px 11px 0;
  color: black;
  height: 3rem;
  border: none;

  :-webkit-autofill {
    -webkit-text-fill-color: rgb(60, 85, 170);
  }

  ::placeholder {
    color: lightgray;
  }
`;

export const ExtraCustomerField = ({
  label,
  id,
  type,
  value,
  onChange,
  placeholder,
  required,
  autoComplete,
}: any) => (
  <ExtraCustomerFormRow>
    <ExtraCustomerFormRowLabel htmlFor={id}>{label}</ExtraCustomerFormRowLabel>
    <ExtraCustomerFormRowInput
      id={id}
      type={type}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
    />
  </ExtraCustomerFormRow>
);

const ExtraCustomerFormRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid lightgray;

  // text-align:left;
  :first-child {
    border-top: none;
  }
`;

const ExtraCustomerFormRowLabel = styled.label`
  width: 100%;
  padding: 5px 0;
  color: gray;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1rem;
  text-align: left;
`;

const ExtraCustomerFormRowInput = styled.input`
  margin-left: 1rem;
  font-size: 1.1rem;
  width: 100%;
  padding: 0.8rem;
  color: black;
  border: none;

  // height: 1rem;

  :-webkit-autofill {
    -webkit-text-fill-color: rgb(60, 85, 170);
  }

  ::placeholder {
    color: lightgray;
  }
`;

export const SubmitButton = ({ processing, children, disabled }: any) => (
  <StyleSubmitButton type="submit" disabled={processing || disabled}>
    {processing ? (
      <ProcessingDiv>
        Processing... <StyleCircularProgress size={25} />
      </ProcessingDiv>
    ) : (
      children
    )}
  </StyleSubmitButton>
);

export const ErrorMessage = ({ children }: any) => (
  <ErrorMessageDiv role="alert">{children}</ErrorMessageDiv>
);

export const ResetButton = ({ onClick }: any) => (
  <button type="button" className="ResetButton" onClick={onClick}>
    <svg width="32px" height="32px" viewBox="0 0 32 32">
      <path
        fill="#FFF"
        d="M15,7.05492878 C10.5000495,7.55237307 7,11.3674463 7,16 C7,20.9705627 11.0294373,25 16,25 C20.9705627,25 25,20.9705627 25,16 C25,15.3627484 24.4834055,14.8461538 23.8461538,14.8461538 C23.2089022,14.8461538 22.6923077,15.3627484 22.6923077,16 C22.6923077,19.6960595 19.6960595,22.6923077 16,22.6923077 C12.3039405,22.6923077 9.30769231,19.6960595 9.30769231,16 C9.30769231,12.3039405 12.3039405,9.30769231 16,9.30769231 L16,12.0841673 C16,12.1800431 16.0275652,12.2738974 16.0794108,12.354546 C16.2287368,12.5868311 16.5380938,12.6540826 16.7703788,12.5047565 L22.3457501,8.92058924 L22.3457501,8.92058924 C22.4060014,8.88185624 22.4572275,8.83063012 22.4959605,8.7703788 C22.6452866,8.53809377 22.5780351,8.22873685 22.3457501,8.07941076 L22.3457501,8.07941076 L16.7703788,4.49524351 C16.6897301,4.44339794 16.5958758,4.41583275 16.5,4.41583275 C16.2238576,4.41583275 16,4.63969037 16,4.91583275 L16,7 L15,7 L15,7.05492878 Z M16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 Z"
      />
    </svg>
  </button>
);

export const ThankYouMessage = ({
  activity: { requiresConfirmation },
}: {
  activity: IActivity_Converted;
}) => {
  // const { state: { selectedItemBook: { requiresConfirmation } }, dispatch } = useFeed()
  // function handleBackToTrip(): void {
  //   dispatch({ type: 'RESET_BOOKING' })
  // }
  return (
    <Result>
      <ResultTitle>Thank You!</ResultTitle>
      <SuccessIcon
        src={
          "https://booking-flow-component.s3.eu-central-1.amazonaws.com/paymentsucceeded.png"
        }
      />

      <ResultMessage pending={requiresConfirmation}>
        {requiresConfirmation
          ? "Your invitation is pending the vendor’s approval. You will recieve an email response for your order within 48 hours."
          : "Please check your inbox for the confirmation email"}
        {/* generated a PaymentMethod: {paymentMethod?.id || 0} */}
      </ResultMessage>

      {/* <Logo src={musementLogo} /> */}
      <BackButton
        color="primary"
        variant="contained"
        // onClick={handleBackToTrip} //TODO - enable button
      >
        Back To Your Trip
      </BackButton>
      {/* <ResetButton
      onClick={resetFields}
    /> */}
    </Result>
  );
};
const BackButton = styled(Button)`
  && {
    margin-left: 1rem;
  }
`;

const SuccessIcon = styled.img`
  height: 5rem;
  margin-bottom: 1rem;
`;

// const Logo = styled.img`
//   height: auto;
//   margin-bottom: 3rem;
//   width: 70%;
// `;

const Result = styled.div`
  margin-top: 50px;
  text-align: center;
  animation: fade 200ms ease-out;
`;
const ResultTitle = styled.div`
  font-weight: 600;
  margin-bottom: 1rem;
  font-size: 2.2rem;
  text-align: center;
`;
const ResultMessage = styled("div")<{ pending: boolean }>`
  font-size: 1rem;
  font-weight: ${(p: any) => (p.pending ? "600" : "400")};
  line-height: 1.6em;
  text-align: center;
  width: 80%;
  padding: 0 2rem;
  margin: auto;
  margin-bottom: 3rem;
  color: ${(p: any) => (p.pending ? "green" : "")};
`;

const ErrorMessageDiv = styled.div`
  color: red;
  font-size: 1rem;
`;

const FormRowLabel = styled.label`
  width: 50%;
  min-width: 70px;
  padding: 11px 0;
  color: gray;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1rem;
  text-align: left;
`;

const ProcessingDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* height:80%; */
  place-content: center;
`;

const StyleCircularProgress = styled(CircularProgress)`
  && {
    color: white;
    margin-left: 1rem;
  }
`;

const StyleSubmitButton = styled.button`
  margin-top: 1rem;
  font-size: 1em;
  width: 100%;
  height: 40px;
  background-color: #228352;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #228352;
  border-radius: 30px;
  color: #fff;
  transition: all 100ms ease-in-out;
  will-change: transform, background-color, box-shadow;

  :disabled {
    opacity: 0.5;
    cursor: default;
    background-color: #7795f8;
    box-shadow: none;
  }
`;
