import React from "react";
import {
  Button,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import useBookTickets, { BookingTicketsContext } from "./useBookingTickets";
import styled from "styled-components";
import PaymentDialog from "../PaymentDialog/PaymentDialog";

interface IProps {
  activity_uuid: string;
  open: boolean;
  handleClose: () => void;
  activity: IActivity_Converted;
}
export default function BookingTicketsPage({ 
  // handleClose, 
  activity }: IProps) {
  const BookingTicketsState = useBookTickets({ activity });
  const { activeStep, steps, stepsDic, handleBack, state } =
    BookingTicketsState;

  return (
    <BookingTicketsContext.Provider value={BookingTicketsState}>
      <Container>
        <StyledStepper activeStep={activeStep} orientation="vertical">
          {steps?.map((label, index) => {
            const { title, Content, selectedOption } = stepsDic[label];
            const EDIT_OPTION: boolean =
              index < activeStep &&
              activeStep !== 0 &&
              index === activeStep - 1;
            const DISPLAY_SELECTED_OPTION: boolean = index < activeStep;
            return (
              <Step key={label}>
                <StepLabel>
                  <StepTitlesContainer>
                    <TextContainer>
                      {title}
                      {DISPLAY_SELECTED_OPTION && (
                        <SelectedText>{selectedOption || ""}</SelectedText>
                      )}
                    </TextContainer>
                    {EDIT_OPTION && (
                      <Button onClick={handleBack} disabled={activeStep === 0}>
                        <StyledEditIcon />
                      </Button>
                    )}
                  </StepTitlesContainer>
                </StepLabel>
                <StyledStepContent>
                  <Content activity={activity} />
                </StyledStepContent>
              </Step>
            );
          })}
        </StyledStepper>
      </Container>
      {state.showPaymentModal && <PaymentDialog activity={activity} />}
    </BookingTicketsContext.Provider>
  );
}

const StyledStepper = styled(Stepper)`
  && {
    width: 85%;
    padding:0;
  }
`;
const Container = styled.div``;

const StyledEditIcon = styled(EditIcon)`
  height: 1.3rem;
  border: 1px solid black;
  padding: 1px 5px;
  border-radius: 8px;
  color: black;
`;
const StyledStepContent = styled(StepContent)`
  text-align: center;
  width: 100%;
  padding:0;
`;

const StepTitlesContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;
const TextContainer = styled.div`
  font-size:1rem;
`;
const SelectedText = styled.div`
  font-weight: 500;
  color: #11721a;
`;
