import React from 'react';
import { getCancellationPolicy } from '../api/musement';

// P = period; D = days (0D = zero days); T = time; H = hours; M = minutes
type TTimeUnits = 'D' | 'H' | 'M';
const TimeUnitsDic: { [key in TTimeUnits]: string } = {
  D: 'day',
  H: 'hour',
  M: 'minute',
};

interface IProps {
  uuid: string;
}

export default function CancellationPolicy({ uuid }: IProps) {
  const [cancellationPolicies, setCancellationPolicies] =
    React.useState<ICancellationPolicy[]>();

  React.useEffect(() => {
    async function fetchCancellationPolicy() {
      try {
        const policy: ICancellationPolicy[] = await getCancellationPolicy(uuid);
        setCancellationPolicies(policy);
      } catch (e) {
        setCancellationPolicies([
          {
            period: 'P0D',
            type: 'PERCENTAGE',
            uuid: 'policy',
            value: 0,
          },
        ]);
      }
    }
    fetchCancellationPolicy();
  }, [uuid]);

  const NoRefundLabel = (
    <p>
      Unfortunately, due to our partner's policy, we can't offer you a refund nor can we
      change or cancel your booking for this particular product.
    </p>
  );

  return cancellationPolicies ? (
    <React.Fragment>
      {cancellationPolicies.map((policy: ICancellationPolicy) => {
        const { value, type, period } = policy;
        const formattedPendingTime: string | null = convertPendingFormat(period);
        const NO_REFUND: boolean = value === 0 && period === 'P0D';
        return NO_REFUND ? (
          NoRefundLabel
        ) : formattedPendingTime ? (
          <p key={`index` + period}>
            Receive a {value}
            {type === 'PERCENTAGE' ? '%' : type} refund if you cancel up to{' '}
            <b> {formattedPendingTime}</b> before the activity begins.
          </p>
        ) : null;
      })}
    </React.Fragment>
  ) : null;
}

function convertPendingFormat(period: string): string | null {
  const lastIndex: number = period.length - 1;
  //@ts-ignore
  const timeUnit: TTimeUnits = period[lastIndex];
  let IndexWithValidDigit: number = lastIndex;
  while (IndexWithValidDigit > 0) {
    const digit: number = +period[IndexWithValidDigit - 1];
    if (isNaN(digit)) {
      break;
    }

    IndexWithValidDigit--;
  }

  const fullNumber: number = +period.slice(IndexWithValidDigit, lastIndex);
  return !isNaN(fullNumber) && fullNumber > 0
    ? `
  ${fullNumber} 
  ${TimeUnitsDic[timeUnit]}${fullNumber > 1 ? 's' : ''}
  `
    : null;
}
