import styled from 'styled-components';

export const SeperationLine = styled.hr`
  width: 100%;
  border-top: 1px solid lightgrey;
  margin: 0.8rem 0;
`;

export const ActivityProperty = styled.h1`
  font-weight: 600;
  font-size: 2.5vh;
`;

export const MapContainerDiv = styled.div`
  position: relative;
  padding-bottom: 260px;
`;
