import React from "react";
import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  Toolbar,
  useMediaQuery,
  useTheme,
  LinearProgress,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { forwardRef, useEffect, useState } from "react";
import styled from "styled-components";
import CloseIcon from "@material-ui/icons/Close";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import BookingInfoPage from "./BookingInfoPage";
import BookingTicketsPage from "../BookingTickets/BookingTicketsPage";
import { getActivity } from "../api/musement";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface IStep {
  Component: React.FC<any>;
  title: string;
  appbar?: {
    bookNowButton?: boolean;
    backButton?: boolean;
  };
}

const StepsDic: { [key in TBookingSteps]: IStep } = {
  Info: {
    Component: BookingInfoPage,
    title: "Activity Info",
    appbar: { bookNowButton: true },
  },
  Tickets: {
    Component: BookingTicketsPage,
    title: "Book Tickets",
    appbar: { backButton: true },
  },
};

//@ts-ignore
const stripePromise = loadStripe(process.env.REACT_APP_MUSEMENT_STRIPE_TOKEN);

export function BookingFlowDialog({
  activityUuid,
  open,
  specificStep,
  onClickCloseButton,
}: IBookingFlowDialogProps) {
  const [step, setStep] = useState<TBookingSteps>(specificStep || "Info");
  const [loading, setLoading] = useState<boolean>(true);
  const [activity, setActivity] = useState<IActivity_Converted>();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  function convertMusementActivity({
    about_html,
    features,
    free_cancellation,
    highlights,
    included,
    not_included,
    info,
    where_text,
    when_text,
    languages,
    meeting_point_html,
    reduced,
    voucher_access_usage,
    latitude,
    longitude,
    title,
    cover_image_url,
    operational_days,
    order_box_elements,
    uuid,
    max_confirmation_time,
    booking_type,
    service_fee,
  }: IActivity): IActivity_Converted {
    const langsList: string[] = languages.map((lang) => lang.name);
    const openDateActivity: boolean = booking_type === "NO-CALENDAR-FIXED-END";
    const maxConfirmationTime: number = +max_confirmation_time[1];
    //activity requires pending if is NaN of if there's confirmation time
    const requiresConfirmation: boolean =
      isNaN(maxConfirmationTime) || maxConfirmationTime > 0;
    const mandatoryPickupService = order_box_elements.some((item) =>
      item.includes("pickup")
    );
    const bookingFee: boolean = service_fee?.value > 0 || false;
    return {
      uuid,
      WhatToExpect_HTML: about_html,
      features,
      free_cancellation,
      DoThisBecause: highlights || null,
      whatsIncluded: included || null,
      whatsNotIncluded: not_included || null,
      WhatToRemember_HTML: info,
      where_HTML: where_text,
      when_HTML: when_text,
      languages: langsList,
      meetingPoint: meeting_point_html,
      reducedPrice_HTML: reduced,
      voucher_access_usage,
      title,
      availability: operational_days,
      cover_image_url,
      location: { lat: latitude, lng: longitude },
      openDateActivity,
      mandatoryPickupService,
      requiresConfirmation,
      bookingType: booking_type,
      bookingFee,
    };
  }

  useEffect(() => {
    async function fetchMusementActivity() {
      //if it has an external_id so it's a musement activity
      try {
        //fetch and convert the activity data from musement
        const activityData: IActivity = await getActivity(activityUuid);
        const convertedActivity: IActivity_Converted =
          convertMusementActivity(activityData);
        setActivity(convertedActivity);
      } catch (error) {
      } finally {
        setLoading(false);
        // setShowSpinner(false)
      }
    }
    fetchMusementActivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleBack() {
    setStep("Info");
  }

  function nextStep(): void {
    setStep("Tickets");
  }

  const { Component, title, appbar } = StepsDic[step];

  return (
    <Elements stripe={stripePromise}>
      <StyledDialog
        fullScreen={fullScreen}
        open={open}
        maxWidth="sm"
        TransitionComponent={Transition}
      >
        <Container>
          <StyledAppBar>
            <Toolbar>
              <IconButton edge="start">
                {appbar?.backButton ? (
                  <StyledBackIcon onClick={handleBack} />
                ) : (
                  <StyledCloseIcon onClick={onClickCloseButton} />
                )}
              </IconButton>

              <AppBarContent>
                {title}
                {appbar?.bookNowButton && !specificStep && (
                  <StyledBarButton variant="contained" onClick={nextStep}>
                    Book Now!
                  </StyledBarButton>
                )}
              </AppBarContent>
            </Toolbar>
          </StyledAppBar>

          <StyledDialogContent>
            {loading ? (
              <LinearProgress />
            ) : (
              <ComponentContainer>
                <Component
                  nextStep={nextStep}
                  specificStep={specificStep}
                  activity={activity}
                />
              </ComponentContainer>
            )}
          </StyledDialogContent>
        </Container>
      </StyledDialog>
    </Elements>
  );
}

const ComponentContainer = styled.div`
  padding: 1rem;
`;

const StyledDialog = styled(Dialog)`
  && {
    overflow-y: overlay;
    overflow-x: hidden;
  }
`;

const Container = styled.div`
  min-width: 600px;
  min-height: 500px;
  box-sizing: border-box;
  @media (max-width: 600px) {
    min-width: 320px;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  && {
    padding: 0 0 5vh 0;
  }
`;
const StyledBackIcon = styled(BackIcon)`
  && {
    color: white;
  }
`;
const StyledCloseIcon = styled(CloseIcon)`
  && {
    color: white;
  }
`;

const StyledBarButton = styled(Button)`
  && {
    color: #3f51b5;
    background-color: white;
    font-weight: 600;
  }
`;

const StyledAppBar = styled(AppBar)`
  && {
    font-size: 1.2rem;
    text-align: center;
    position: sticky;
  }
`;

const AppBarContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-weight: 600;
`;
