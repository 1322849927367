import React from "react";
import "./index.css";
import { BookingFlowDialog } from "./BookingInfo/BookingFlow";

interface IProps {
  open: boolean;
  activityUuid: string; //attraction uuid '4bffde2a-eaec-412f-939a-d3f1c4b0ed65'
  onClickCloseButton: () => void; // The function that closes the modal
  // source?: "GoogleMaps" | "Viator" | "Musement" | "Tiqets" | "TicketMaster";
  specificStep?: TBookingSteps;
}
export default function BookingFlowComponent({
  activityUuid,
  onClickCloseButton,
  open,
  specificStep,
}: IProps) {
  return (
    <BookingFlowDialog
      open={open}
      activityUuid={activityUuid}
      onClickCloseButton={onClickCloseButton}
      specificStep={specificStep}
    />
  );
}
