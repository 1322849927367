import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import styled, { keyframes } from "styled-components";
import { useBookingTicketsContext } from "../BookingTickets/useBookingTickets";
import {
  Field,
  ThankYouMessage,
  SubmitButton,
  ExtraCustomerField,
} from "./subcomponents";
import usePaymentDialog from "./usePaymentDialog";
import {
  AppBar,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  LinearProgress,
  Box,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import SecureIcon from "@material-ui/icons/Lock";
import ErrorAlert from "../subcomponents/ErrorAlert";
interface IProps {
  activity: IActivity_Converted;
}
export default function PaymentDialog({ activity }: IProps) {
  const {
    totalPaymentValue,
    state: { showPaymentModal },
    dispatch,
  } = useBookingTicketsContext();

  const {
    handleSubmit,
    stripe,
    billingDetails,
    error,
    setError,
    setCardComplete,
    processing,
    setBillingDetailsDynamic,
    setExtraBillingDetailsDynamic,
    completedProcess,
    progressValue,
    loading,
    extraFields,
  } = usePaymentDialog({ activity });

  const {
    currency,
    totalSum: { retail_price },
  } = totalPaymentValue();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  function handleClosePaymentDialog(): void {
    dispatch({ type: "SHOW_PAYMENT_MODAL", payload: false });
  }

  const LoadingPaymentMessage = () => (
    <LoaderContainer>
      {error ? (
        <ErrorAlert>{error.message}</ErrorAlert>
      ) : (
        <React.Fragment>
          <StyledLinearProgress />
          <SecurePaymentImg src="https://booking-flow-component.s3.eu-central-1.amazonaws.com/securepayment.png" />
          <LoaderText>Loading Secure Payment</LoaderText>
        </React.Fragment>
      )}
    </LoaderContainer>
  );
  return (
    <Dialog
      fullScreen={fullScreen}
      open={showPaymentModal}
      maxWidth="sm"
      TransitionComponent={Transition}
    >
      {/* <DialogTitle>        Book Now      </DialogTitle> */}
      <AppBar style={{ position: "relative" }}>
        <Toolbar>
          <StyledIconButton edge="start" color="inherit" aria-label="close">
            {/* {!(processing || completedProcess) && */}
            {!completedProcess && (
              <BackIcon onClick={handleClosePaymentDialog} />
            )}
          </StyledIconButton>
          <Typography variant="h6">Payment</Typography>
        </Toolbar>
      </AppBar>
      {completedProcess ? (
        <Container>
          <ThankYouMessage activity={activity} />
        </Container>
      ) : loading ? (
        <LoadingPaymentMessage />
      ) : (
        <Container>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Field
                required
                label="First Name"
                id="firstname"
                type="text"
                autoComplete="firstname"
                value={billingDetails.firstname}
                onChange={(e: any) => setBillingDetailsDynamic(e, "firstname")}
              />
              <Field
                required
                label="Last Name"
                id="lastname"
                type="text"
                autoComplete="lastname"
                value={billingDetails.lastname}
                onChange={(e: any) => setBillingDetailsDynamic(e, "lastname")}
              />
              <Field
                required
                label="Email"
                id="email"
                type="email"
                autoComplete="email"
                value={billingDetails.email}
                onChange={(e: any) => setBillingDetailsDynamic(e, "email")}
              />
            </FormGroup>
            <FormGroup>
              {extraFields
                ? Object.keys(extraFields)
                    //@ts-ignore
                    ?.map((key: TExtraCustomerFields) => {
                      const { title, type } = extraFields[key];
                      return (
                        <ExtraCustomerField
                          required
                          label={title}
                          id={key}
                          type={type}
                          placeholder=""
                          autoComplete={key}
                          value={
                            billingDetails?.extra_customer_data
                              ? billingDetails?.extra_customer_data[key]
                              : ""
                          }
                          onChange={(e: any) =>
                            setExtraBillingDetailsDynamic(e, key)
                          }
                        />
                      );
                    })
                : null}
            </FormGroup>
            <ImageContainer>
              <img
                height="20px"
                src="https://booking-flow-component.s3.eu-central-1.amazonaws.com/creditcards.png"
                alt="payment"
              />
            </ImageContainer>
            <FormGroup>
              <StyledCardElement
                options={stylesObject}
                onChange={(e: any) => {
                  setError(e.error);
                  setCardComplete(e.complete);
                }}
              />
            </FormGroup>
            <SecurePaymentContainer>
              <StyledSecureIcon />
              <SecurePaymentLabel>
                <i>
                  All data is securely encrypted and available only to the
                  credit card processor
                </i>
              </SecurePaymentLabel>
            </SecurePaymentContainer>
            {error && <ErrorAlert>{error?.message}</ErrorAlert>}
            {processing && <LinearProgressWithLabel value={progressValue} />}
            <SubmitButton
              processing={processing}
              error={error}
              disabled={!stripe || error}
            >
              Pay ({currency}
              {retail_price})
            </SubmitButton>
          </Form>{" "}
        </Container>
      )}
    </Dialog>
  );
}

function LinearProgressWithLabel({ value }: { value: number }) {
  return (
    <Box display="flex" width="80%" alignItems="center" marginTop="1rem">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={value} />
      </Box>
      <Box minWidth={35}>
        <Typography
          variant="body2"
          color="textSecondary"
        >{`${value}%`}</Typography>
      </Box>
    </Box>
  );
}

const StyledLinearProgress = styled(LinearProgress)`
  && {
    width: 100%;
    margin: 0;
    padding: 0;
  }
`;

const StyledCardElement = styled(CardElement)`
  && {
    width: 95%;
    padding: 11px 15px 11px 5px;
  }
`;

const Container = styled.div`
  min-width: 600px;
  min-height: 500px;
  padding: 1rem;
  box-sizing: border-box;
  @media (max-width: 600px) {
    min-width: 320px;
  }
  input,
  button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-style: none;
  }
`;
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SecurePaymentImg = styled.img`
  width: 50%;
  height: auto;
  margin-top: 5rem;
`;

const FormGroup = styled.fieldset`
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: white;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  width: 100%;
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const LoaderText = styled.h2`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 400;
`;

const ImageContainer = styled.div`
  width: 100%;
  place-content: left;
  margin-left: 1rem;
`;

const stylesObject = {
  hidePostalCode: true,
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "black",
      // fontWeight: 500,
      fontSize: "1em",
      ":-webkit-autofill": {
        color: "#fce883",
      },
      "::placeholder": {
        color: "#87bbfd",
      },
    },
    invalid: {
      iconColor: "#de1b1b",
      color: "#de1b1b",
    },
  },
};

const fade = keyframes`
  from {
    opacity: 1;
    transform: scale3D(0.6, 0.95, 0.95);
  }
  to {
    opacity: 1;
    transform: scale3D(1, 1, 1);
  }
`;

const Form = styled.form`
  animation: ${fade} 200ms ease-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
`;

const SecurePaymentLabel = styled.span`
  font-size: 0.8rem;
  color: grey;
  padding: 0 1rem;
`;

const SecurePaymentContainer = styled.div`
  display: flex;
  align-content: center;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom:1rem;
`;

const StyledSecureIcon = styled(SecureIcon)`
  color: grey;
`;
const StyledIconButton = styled(IconButton)`
  min-width: 3rem;
`;
