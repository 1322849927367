import {
  createContext,
  Dispatch,
  useContext,
  useReducer,
  useState,
} from 'react';
import Dates from './Dates';
import PickUpPoints from './PickupPoints';
import Groups from './Groups';
import Products from './Products';
import PaymentDialog from '../PaymentDialog/PaymentDialog';
interface stepFields {
  title: string;
  Content: any;
  selectedOption?: string | null;
}

interface IState {
  selectedPickup?: IPickupPoint;
  selectedDate?: Date;
  products: IProduct[];
  timeSlot?: ISlot;
  // timeSlotIndex: number;
  selectedGroup?: IGroup;
  language?: ILanguage;
  selectedLanguageIndex?: number;
  showPaymentModal: boolean;
  // time?: string;
}

const initialState: IState = {
  products: [],
  // timeSlotIndex: 0,
  selectedLanguageIndex: 0,
  showPaymentModal: false,
};

type TBookingActions =
  | 'SELECTED_PICKUP'
  | 'SELECTED_DATE'
  | 'SELECTED_GROUP'
  | 'LANGUAGE'
  | 'TIMESLOT'
  | 'PRODUCTS'
  | 'SHOW_PAYMENT_MODAL';
// | "TIME" |"TIMESLOT_INDEX" |
interface IAction {
  type: TBookingActions;
  payload: any;
}
interface IProps {
  activity: IActivity_Converted;
}
function reducer(state: IState, action: IAction): IState {
  const { type, payload } = action;
  switch (type) {
    case 'SELECTED_PICKUP':
      return { ...state, selectedPickup: payload };
    case 'SELECTED_DATE':
      return { ...state, selectedDate: payload };
    case 'SELECTED_GROUP':
      return { ...state, selectedGroup: payload };
    case 'LANGUAGE':
      return { ...state, language: payload };
    case 'TIMESLOT':
      return { ...state, timeSlot: payload };
    case 'SHOW_PAYMENT_MODAL':
      return { ...state, showPaymentModal: payload };
    // case "SELECTED_LANGUAGE_INDEX":
    //   return { ...state, selectedLanguageIndex: payload }
    case 'PRODUCTS':
      return { ...state, products: payload };
    // case "TIMESLOT_INDEX":
    //   return { ...state, timeSlotIndex: payload }
    // case "TIME":
    //   return { ...state, time: payload }

    default:
      return { ...state };
  }
}
export default function useBookingTickets({ activity }: IProps): IUseBookingDialog {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { selectedPickup, selectedDate, products, timeSlot, selectedGroup, language } =
    state;

  const StepsToBookingTypeMapper: { [key in TBookingTypes]: string[] } = {
    'CALENDAR-TIMESLOTS': ['dates', 'groups', 'products'],
    'CALENDAR-NO-TIMESLOTS': ['dates', 'groups', 'products'], //TODO - no time selection
    'NO-CALENDAR-FIXED-END': ['groups', 'products'], //TODO- no dates, no time
    'NO-CALENDAR-FIXED-VALIDITY': ['dates', 'groups', 'products'], //TODO - no time selection
  };

  const steps: string[] = activity
    ? activity.mandatoryPickupService
      ? ['pickup', ...StepsToBookingTypeMapper[activity.bookingType]]
      : StepsToBookingTypeMapper[activity.bookingType]
    : [];

  const [activeStep, setActiveStep] = useState(0);

  function handleNext() {
    setActiveStep(activeStep + 1);
  }

  function handleBack() {
    setActiveStep(function (prevActiveStep: number) {
      //reseting the old state
      if (steps[prevActiveStep] === 'groups') {
        dispatch({ type: 'SELECTED_GROUP', payload: undefined });
        dispatch({ type: 'TIMESLOT', payload: undefined });
        dispatch({ type: 'LANGUAGE', payload: undefined });
      }
      //Returning the new step
      return prevActiveStep !== 0 ? prevActiveStep - 1 : prevActiveStep;
    });
  }

  function handleReset() {
    setActiveStep(0);
  }

  const TIMESLOTS_OPTIONS_DISPLAY: boolean =
    activity?.bookingType === 'CALENDAR-TIMESLOTS';

  const stepsDic: { [key: string]: stepFields } = {
    pickup: {
      title: 'Select Pickup Point ',
      Content: PickUpPoints,
      selectedOption: selectedPickup?.name,
    },
    dates: {
      title: 'Select Date ',
      Content: Dates,
      selectedOption:  selectedDate?.toISOString().split('T')[0],
    },
    groups: {
      title: 'Select Activity ',
      Content: Groups,
      selectedOption:
        selectedGroup !== undefined
          ? `${selectedGroup?.name}
        ${language ? `• ${language.name}` : ''} ${
              TIMESLOTS_OPTIONS_DISPLAY ? `• ${timeSlot?.time}` : ''
            }`
          : null,
    },
    products: {
      title: 'Select Tickets ',
      Content: Products,
    },
    payment: {
      title: 'Secure Payment ',
      Content: PaymentDialog,
    },
  };

  const totalPaymentValue = (): {
    totalSum: {
      retail_price: number;
      retail_price_without_service_fee: number;
      service_fee: number;
    };
    currency: TCurrencies;
  } => {
    let totalPriceWithoutServiceFee: number = 0;
    let totalServiceFee = 0;
    products.forEach((product: IProduct, ) => {
      const {
        // retail_price: { value: retail_price_value, currency },
        service_fee: { value: service_fee_value },
        retail_price_without_service_fee: { value: price_without_service_fee_value },
      } = product;
      // const { original_retail_price: { value, currency } } = product;
      const amount: number = product.amount || 0;
      if (amount && amount > 0) {
        totalPriceWithoutServiceFee += price_without_service_fee_value * amount;
        totalServiceFee += service_fee_value * amount;
        // setSelectedProductsList({ ...selectedProductsList, [index]: product })
      }
    });
    const totalPriceIncludingServiceFee: number =
      totalPriceWithoutServiceFee + totalServiceFee;
    return {
      totalSum: {
        retail_price: totalPriceIncludingServiceFee,
        retail_price_without_service_fee: totalPriceWithoutServiceFee,
        service_fee: totalServiceFee,
      },
      //@ts-ignore
      currency: CurrencySymbolMap[products[0]?.original_retail_price?.currency] || '$',
    };
  };

  const exports: IUseBookingDialog = {
    activeStep,
    steps,
    stepsDic,
    state,
    dispatch,
    handleBack,
    handleReset,
    handleNext,
    totalPaymentValue,
    // selectedProductsList, setSelectedProductsList,
  };
  return exports;
}

export const BookingTicketsContext = createContext<IUseBookingDialog>({
  activeStep: 0,
  dispatch: () => {},
  handleBack: () => {},
  handleNext: () => {},
  handleReset: () => {},
  state: initialState,
  steps: [],
  stepsDic: {},
  totalPaymentValue: () => {
    return {
      totalSum: {
        retail_price: 0,
        retail_price_without_service_fee: 0,
        service_fee: 0,
      },
      currency: 'USD',
    };
  },
});

export const useBookingTicketsContext = () =>
  useContext<IUseBookingDialog>(BookingTicketsContext);

export interface IUseBookingDialog {
  handleNext: () => void;
  state: IState;
  dispatch: Dispatch<IAction>;
  // setLang: Dispatch<SetStateAction<boolean>>
  // selectedProductsList: { [index: number]: IProduct };
  // setSelectedProductsList: Dispatch<SetStateAction<{ [index: number]: IProduct }>>;

  // showPaymentDialog: boolean,
  // setShowPaymentDialog: Dispatch<SetStateAction<boolean>>;

  activeStep: number;
  steps: string[];
  stepsDic: { [key: string]: stepFields };

  totalPaymentValue: () => {
    totalSum: {
      retail_price: number;
      retail_price_without_service_fee: number;
      service_fee: number;
    };
    currency: TCurrencies;
  };
  handleBack: () => void;
  handleReset: () => void;
}

export const CurrencySymbolMap: { [key in TCurrencies]: string } = {
  EUR: '€',
  USD: '$',
  GBP: '£',
  CHF: 'CHF',
  CAD: 'C$',
  AUD: 'AU$',
  AED: 'د.إ',
  ARS: 'ARS',
  AZN: 'ман',
  BGN: 'лв',
  BHD: '.د.ب',
  CLP: 'CLP$',
  CNY: '¥',
  COP: 'COL$',
  CVE: 'Esc',
  CZK: 'Kč',
  DKK: 'DKK',
  DOP: 'RD$',
  EGP: 'ج.م',
  FJD: 'FJ$',
  GEL: 'ლ',
  HKD: 'HK$',
  HRK: 'kn',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  INR: '₹',
  ISK: 'Íkr',
  JMD: 'J$',
  JOD: 'JD',
  JPY: '¥',
  KRW: '₩',
  KWD: 'د.ك',
  KZT: '₸',
  MDL: 'L',
  MXN: 'Mex$',
  MYR: 'RM',
  NAD: 'N$',
  NOK: 'NOK',
  NZD: 'NZ$',
  OMR: 'ر.ع.',
  PLN: 'zł',
  QAR: 'ر.ق',
  RON: 'lei',
  RUB: 'руб',
  SAR: '﷼',
  SEK: 'kr',
  SGD: 'S$',
  THB: '฿',
  TND: 'د.ت',
  TRY: '₺',
  TWD: 'NT$',
  UAH: '₴',
  XOF: 'CFA',
  ZAR: 'R',
};
