/**
 * Saves an item into the session storage
 * @param key
 * @param value
 * @returns the value was saved
 */
const saveSessionItem = (key: string, value: any) => {
  if (!key || !value) return;
  return sessionStorage.setItem(key, JSON.stringify(value));
};

/**
 * @param key
 * @returns value of the item in the session storage
 */
const getSessionItem = (key: string) => JSON.parse(sessionStorage.getItem(key) || 'null');

export function getMusementToken(): IMusementToken | null {
  return getSessionItem('MusementToken') || null;
}

export function saveMusementToken(musementToken: IMusementToken) {
  saveSessionItem('MusementToken', musementToken);
}
