import axios from 'axios';
import { getLoginMusementToken } from './musement';
// import * as Sentry from '@sentry/react';

const MUSEMENT_API = 'https://api.musement.com/api/v3';

const instance = axios.create({
  baseURL: MUSEMENT_API,
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json',
    'X-Musement-Currency': 'USD',
  },
});
type IData = { [key: string]: string } | null | any;

async function musement_get(path: string) {
  return get(path);
}

async function musement_post(path: string, data?: IData) {
  return post(path, data);
}

async function musement_put(path: string, data?: IData) {
  return put(path, data);
}

async function musement_delete(path: string) {
  return deletee(path);
}

//////////////////////////////====>Calls<====//////////////////////////////////////////////////

async function get(path: string) {
  const accessToken = await getLoginMusementToken();
  try {
    const response = await instance.get(path, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response.data;
  } catch (error) {
    // Sentry.captureException(error);
    throw new Error(error);
  }
}

async function post(path: string, data?: IData) {
  const accessToken = await getLoginMusementToken();
  try {
    const response = await instance.post(path, data, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response.data;
  } catch (error) {
    // Sentry.captureException(error);
    throw new Error(error);
  }
}
async function put(path: string, data?: IData) {
  const accessToken = await getLoginMusementToken();
  try {
    const response = await instance.put(path, data, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response.data;
  } catch (error) {
    // Sentry.captureException(error);
    throw new Error(error);
  }
}
async function deletee(path: string) {
  const accessToken = await getLoginMusementToken();
  try {
    const response = await instance.delete(path, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response.data;
  } catch (error) {
    // Sentry.captureException(error);
    throw new Error(error);
  }
}

const Calls = { 
  get: musement_get,
  post: musement_post,
  put: musement_put,
  delete: musement_delete,
};

export default Calls;
