import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from 'styled-components';

const Covid19InfoList: string[] = [
  'Visitor numbers are limited to reduce crowds',
  'Social distancing is enforced',
  'Guides and all other staff are required to wear masks',
  'The experience complies with government regulations',
  'Visitors are required to bring and wear masks',
  'Temperature screening is compulsory for all visitors',
];

enum MUSEMENT_COMP2 {
  DoThisBecause,
  WhatToExpect,
  CovidInfo,
  WhatsIncluded,
  WhatsNotIncluded,
  WhatToRemember,
}

interface IDetail {
  title: string;
  content: JSX.Element | null;
}

interface IProps {
  activity: IActivity_Converted;
}

export default function PartTwo({ activity }: IProps) {
  const {
    DoThisBecause,
    whatsIncluded,
    whatsNotIncluded,
    WhatToRemember_HTML,
    WhatToExpect_HTML,
  } = activity;

  const Component2Dic: { [key in MUSEMENT_COMP2]: IDetail } = {
    [MUSEMENT_COMP2.DoThisBecause]: {
      title: 'Do This Because',
      content: DoThisBecause ? (
        <ul>
          {DoThisBecause?.map((text: string) => (
            <React.Fragment>
              <li>{text}</li>
              <br />
            </React.Fragment>
          ))}
        </ul>
      ) : null,
    },
    [MUSEMENT_COMP2.WhatToExpect]: {
      title: 'What To Expect',
      content: <div dangerouslySetInnerHTML={{ __html: WhatToExpect_HTML }} />,
    },
    [MUSEMENT_COMP2.CovidInfo]: {
      title: 'Covid19 Information',
      content: (
        <ul>
          {Covid19InfoList.map((text: string) => (
            <React.Fragment>
              <li>{text}</li>
              <br />
            </React.Fragment>
          ))}
        </ul>
      ),
    },
    [MUSEMENT_COMP2.WhatsIncluded]: {
      title: "What's Included",
      content: whatsIncluded ? (
        <ul>
          {whatsIncluded?.map((text: string) => (
            <React.Fragment>
              <li>{text}</li>
              <br />
            </React.Fragment>
          ))}
        </ul>
      ) : null,
    },
    [MUSEMENT_COMP2.WhatsNotIncluded]: {
      title: "What's Not Included",
      content: whatsNotIncluded ? (
        <ul>
          {whatsNotIncluded?.map((text: string) => (
            <React.Fragment>
              <li>{text}</li>
              <br />
            </React.Fragment>
          ))}
        </ul>
      ) : null,
    },
    [MUSEMENT_COMP2.WhatToRemember]: {
      title: 'What To Remember',
      content: WhatToRemember_HTML ? (
        <div dangerouslySetInnerHTML={{ __html: WhatToRemember_HTML }} />
      ) : null,
    },
  };

  return (
    <AccordionContainer>
      {Array.from(Array(Object.keys(MUSEMENT_COMP2).length / 2).keys()).map(
        (key: MUSEMENT_COMP2, index) => {
          const { content, title } = Component2Dic[key];
          return content ? (
            <Accordion
              key={title}
              defaultExpanded={
                index === MUSEMENT_COMP2.WhatsIncluded ||
                index === MUSEMENT_COMP2.WhatsNotIncluded
              }>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header">
                <StyledTypography>{title}</StyledTypography>
              </AccordionSummary>
              <StyledAccordionDetails>{content}</StyledAccordionDetails>
            </Accordion>
          ) : null;
        },
      )}
    </AccordionContainer>
  );
}
// const SeperationLine = styled.hr`
//   width: 100%;
//   border-top: 1px solid lightgrey;
//   margin: 0.8rem 0;
// `;

const StyledAccordionDetails = styled(AccordionDetails)`
  && {
    flex-direction: column;
  }
`;
const StyledTypography = styled(Typography)`
  && {
    font-size: 1rem;
    font-weight: 500;
  }
`;
const AccordionContainer = styled.div`
  margin-top: 1rem;
`;
