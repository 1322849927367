import styled from "styled-components";
import { Select, MenuItem, FormControl } from "@material-ui/core";
import { Dispatch } from "react";
import React, { SetStateAction } from "react";

interface IProps {
  // handleSelectTimeSlot: (slot: ISlot) => void;
  selectedTimeSlot?: ISlot;
  setSelectedTimeSlot: Dispatch<SetStateAction<ISlot | undefined>>;
  slots: ISlot[];
}

export function TimeSelection({
  slots,
  selectedTimeSlot,
  setSelectedTimeSlot,
}: IProps) {
  // const { state: { timeSlotIndex } } = useBookingDialogContext();
  // const [selectedTimeSlot, setSelectedTimeSlot] = useState<ISlot>(slots[0])

  // function handleSelectTimeSlot(timeSlot: ISlot) {
  //   setSelectedTimeSlot(timeSlot);
  //   // providerDispatch({ type: "TIMESLOT_INDEX", payload: TimeSlotIndex })
  //   // providerDispatch({ type: "PRODUCTS", payload: slots[TimeSlotIndex].products })
  //   // providerDispatch({ type: "TIME", payload: slots[TimeSlotIndex].time })
  // }
  function convert24HTimeToAMPM(time: string): string {
    let [hour, mins] = time.split(":");
    const hourValue: number = +hour;
    if (isNaN(hourValue)) return time;

    const convertedHour: number = hourValue % 12 || 12;
    const ampm: "AM" | "PM" = hourValue < 12 || hourValue === 24 ? "AM" : "PM";

    return `${convertedHour}:${mins} ${ampm}`;
  }

  const SINGLE_TIMESLOT = slots.length <= 1;
  return (
    <React.Fragment>
      <TimeSlotsContainer>
        {!SINGLE_TIMESLOT ? (
          <React.Fragment>
            <TimeSlotsLabel>Time:</TimeSlotsLabel>
            <StyledFormControl variant="outlined">
              <StyledSelect value={selectedTimeSlot?.time}>
                {slots?.map((timeslot: ISlot, index) => {
                  const { time } = timeslot;
                  return (
                    <MenuItem
                      key={`ts ${time}` + index}
                      value={index}
                      onClick={() => setSelectedTimeSlot(timeslot)}
                    >
                      {convert24HTimeToAMPM(time)}
                    </MenuItem>
                  );
                })}
              </StyledSelect>
            </StyledFormControl>
          </React.Fragment>
        ) : (
          <TimeSlotsLabel>
            Time: {convert24HTimeToAMPM(slots[0].time)}
          </TimeSlotsLabel>
        )}
      </TimeSlotsContainer>
    </React.Fragment>
  );
}

const StyledSelect = styled(Select)`
  && {
    &.MuiInputBase-input {
      padding: 0.7rem;
    }
  }
`;

const StyledFormControl = styled(FormControl)`
  && {
    width: 100%;
    min-width: fit-content;
    margin-bottom: 1rem;
  }
`;

const TimeSlotsLabel = styled.div`
  margin-top: 0.5rem;
  margin-right: 1rem;
`;

const TimeSlotsContainer = styled.div`
  margin-bottom: 1rem;
`;
