import React from "react";
import { Button } from "@material-ui/core";
import styled from "styled-components";
import PartTwo from "./Parts/PartTwo";
import PartOne from "./Parts/PartOne";
import PartThree from "./Parts/PartThree";

interface IProps {
  nextStep: () => void;
  activity: IActivity_Converted;
  specificStep?: TBookingSteps;
}

export default function BookingInfoPage({
  nextStep,
  activity,
  specificStep,
}: IProps) {
  if (!activity) return null;

  return (
    <Container>
      <CoverImage image={activity?.cover_image_url} />
      <Title>{activity.title}</Title>
      <PartOne activity={activity} />
      <PartTwo activity={activity} />
      <PartThree activity={activity} />
      {!specificStep && (
        <StyledButton variant="contained" color="primary" onClick={nextStep}>
          Book Now!
        </StyledButton>
      )}
    </Container>
  );
}
const Container = styled.div``;

const CoverImage = styled("img")<{ image: string }>`
  background-image: url(${(p: { image: string }) => p.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center, center center;
  height: 200px;
  object-fit: cover;
  width: 100%;
`;
const Title = styled.div`
  font-weight: 700;
  font-size: 1.3rem;
  margin-bottom: 0.3rem;
  text-transform: capitalize;
`;
const StyledButton = styled(Button)`
  && {
    width: 100%;
    height: 3rem;
  }
`;
