import axios from 'axios';
import Calls from './calls';
import { getMusementToken, saveMusementToken } from './storageService';

enum Endpoints {
  createTrip = '/create-trip/',
  deleteEvent = '/delete-event/',
  tripDetails = '/trip-details/',
  addEvent = '/add-event/',
  dayItinerary = '/day-itinerary/',
  moreAttractions = '/more-attractions/',
  clientDesign = '/get-client-design/',
  setAttractionStatus = '/set-attraction-status/',
  sendVerificationEmail = '/send-verification-email/',
  verifycode = '/verify-code-from-email/',
}
const MUSEMENT_URL = {
  activities: `/activities`,
  login: `/login`,
  carts: `/carts`,
  orders: `/orders`,
  payments: `/payments/split/payment`,
};
const {
  REACT_APP_MUSEMENT_CLIENT_ID_MERCHANT: clientID,
  REACT_APP_MUSEMENT_CLIENT_SECRET_MERCHANT: clientSecret,
  // REACT_APP_API_ENDPOINT: BRIDGIFY_API,
} = process.env;

export async function getLoginMusementToken(): Promise<string> {
  const EXPERATION_PERIOD = 60 * 100 * 14; //14 minutes

  //check if there's a token in the session storage
  const cachedToken: IMusementToken | null = getMusementToken();
  if (cachedToken) {
    const { timestamp, value } = cachedToken;
    const nowTime = Date.now();
    // check its expiration time compares to the Date.Now
    const timeGap = nowTime - timestamp;
    if (timeGap < EXPERATION_PERIOD) {
      //if not expired => return token;
      return value;
    }
  }
  //if expired => get new token; save it in session storage; return it
  const URL = `https://api.musement.com/api/v3${MUSEMENT_URL.login}?client_id=${clientID}&client_secret=${clientSecret}&grant_type=client_credentials`;
  const { data: loginToken } = await axios.get(URL);
  const newToken: IMusementToken = {
    value: loginToken.access_token,
    timestamp: Date.now(),
  };
  saveMusementToken(newToken);
  return newToken.value;
}

// GET {{api_base_url}}/activities/{uuid}/dates?date_from=YYYY-MM-DD&date_to=YYYY-MM-DD
export async function getActivity(uuid: string) {
  const URL = `${MUSEMENT_URL.activities}/${uuid}`;
  const activity: IActivity = await Calls.get(URL);
  return activity;
}

// GET {{api_base_url}}/activities/{uuid}/dates?date_from=YYYY-MM-DD&date_to=YYYY-MM-DD
export async function getDatesByActivityID(
  uuid: string,
  start_date?: string | null,
  finish_date?: string | null,
  pickupPoint?: string,
) {
  if (!start_date || !finish_date) throw new Error('dates range are missing');

  const datesRangeString = `dates?date_from=${start_date}&date_to=${finish_date}`;
  const pickupString = `&pickup=${pickupPoint}`;

  // const URL = `${MUSEMENT_URL.activities}/${uuid}/${pickupPoint ? pickupString : ''}`;
  const URL = `${MUSEMENT_URL.activities}/${uuid}/${datesRangeString}${pickupPoint ? pickupString : ''
    }`;
  const dates: IDate[] = await Calls.get(URL);
  // const dates = require("../../assets/mocks/Dates.json");
  if (dates.length === 0) throw new Error('no date available');
  return dates;
}

// GET   https://{{api_base_url}}/api/v3/activities/{activity_uuid}/pickups
export async function getPickupsByActivityID(uuid: string) {
  const URL = `${MUSEMENT_URL.activities}/${uuid}/pickups`;
  const pickups: IPickupPoint[] = await Calls.get(URL);
  // const pickups: IPickupPoint[] = require("../../assets/mocks/PickupsPoints.json");
  return pickups;
}

// GET {{api_base_url}}/activities/{uuid}/dates/YYYY-DD-MM
export async function getGroupsByDate(uuid: string, date?: string, pickupPoint?: string) {
  if (!uuid) throw new Error();
  let URL: string;
  const pickupString = `?pickup=${pickupPoint}`;
  if (!date) {
    const today = new Date();
    const year = today.getFullYear();
    URL = `${MUSEMENT_URL.activities}/${uuid}/dates/${year}-12-31`;
  } else {
    URL = `${MUSEMENT_URL.activities}/${uuid}/dates/${date}${pickupPoint ? pickupString : ''
      }`;
  }

  const data = await Calls.get(URL);
  // const groups = require("../../assets/mocks/Day.json")[0].groups;
  return data[0]?.groups || [];
}

export async function CreateCart() {
  const response: ICart = await Calls.post(MUSEMENT_URL.carts, {});
  const { uuid } = response;
  return uuid;
}

export async function addItemToCart(createdCartUUID: string, productDetails: any) {
  const URL = `${MUSEMENT_URL.carts}/${createdCartUUID}/items`;

  const response: any = await Calls.post(URL, productDetails);
  return response;
}

export async function getCart(CartUUID: string) {
  const URL = `${MUSEMENT_URL.carts}/${CartUUID}`;
  const response: ICart = await Calls.get(URL);
  return response;
}

// PUT https://sandbox.Calls.com/api/v3/carts/cartUuid/customer
export async function putCartCustomer(CartUUID: string, customer: IBillingDetails) {
  const { email, firstname, lastname, extra_customer_data } = customer;
  const cartCustomer: ICartCustomer = {
    email,
    firstname,
    lastname,
    musement_newsletter: 'NO',
    events_related_newsletter: 'NO',
    thirdparty_newsletter: 'NO',
  };
  if (extra_customer_data) {
    cartCustomer['extra_customer_data'] = extra_customer_data;
  }
  const URL = `${MUSEMENT_URL.carts}/${CartUUID}/customer`;
  const response: any = await Calls.put(URL, cartCustomer);
  return response;
}

// Now that the cart is created and all the information has been set, you now need to create the order
//   POST https://api.Calls.com/api/v3/orders \
//   -H 'Authorization: Bearer valid' \
//   -H 'Cache-Control: no-cache' \
//   -H 'Content-Type: application/json' \
//   -H 'X-Musement-Version: 3.4.0' \
//   -d '{"cart_uuid" : "[CART_UUID]"}'

export async function makeAnOrder(cart_uuid: string) {
  const response: IOrder = await Calls.post(MUSEMENT_URL.orders, { cart_uuid });
  return response;
}

// Pay an order with stripe!
//  POST https://api.Calls.com/api/v3/payments/split/payment \
//   -H 'Accept: application/json, application/xml' \
//   -H 'Cache-Control: no-cache' \
//   -H 'Content-Type: application/json' \
//   -d '{
//     "order_uuid": "valid-order-uuid-here",
//     "stripe_token": "stripe_payment_method_id_here"
// }
// '
export async function payOrder(body: any) {
  const response = await Calls.post(MUSEMENT_URL.payments, body);
  return response;
}

// The customer is the person who buy the ticket. These data are used to create the invoice and,
//  if the activity does not require a custom participants info
export async function getCustomerSchema(cardUUID: string) {
  const URL = `${MUSEMENT_URL.carts}/${cardUUID}/customer/schema`;
  const response = await Calls.get(URL);
  return response;
}

// https://api.Calls.com/api/v3/activities/{uuid}/refund-policies
export async function getCancellationPolicy(
  uuid: string,
): Promise<ICancellationPolicy[]> {
  const URL = `${MUSEMENT_URL.activities}/${uuid}/refund-policies`;
  const response = await Calls.get(URL);
  return response;
}
// https://api.Calls.com/api/v3/orders/{order_uuid}/items/{order-item_uuid}/refund-policies
export async function getCancellationPolicyForOrder(
  uuid: string,
  orderUuid: string,
): Promise<ICancellationRemainingTime[]> {
  const URL = `${MUSEMENT_URL.orders}/${uuid}/items/${orderUuid}/refund-policies`;
  const response = await Calls.get(URL);

  // const response: ICancellationPolicy[] = [
  //   {
  //     "uuid": "cdf7e764-abc9-44a5-9dd5-2084eabe806d",
  //     "period": "P0DT72H0M",
  //     "type": "PERCENTAGE",
  //     "value": 100
  //   },
  //   {
  //     "uuid": "291ac43a-7036-4c67-b193-f8ed858c4813",
  //     "period": "P0DT24H0M",
  //     "type": "PERCENTAGE",
  //     "value": 50
  //   }
  // ]

  return response;
}

// setting Musement booked status
// endpoint : https://api.dev.bridgify.io/attraction-booked/
interface ISetMusementActivityStatus {
  // trip_id: string | null;
  item_id: 0; // 0 because there's no related trip
  order_id: string;
  status: 'BOOKED' | 'PENDING' | 'CANCELLED';
  pickup?: IPickupDetails;
  activity_datetime?: string;
  merchant_flow?: boolean;
  client: 'expenture';
}
export async function setMusementActivityStatus(data: ISetMusementActivityStatus) {
  await axios.post('https://api.bridgify.io' + Endpoints.setAttractionStatus, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer c474c424-8650-45d3-8134-538c81795bbd`,
    },
  });
}

//https://sandbox.Calls.com/api/v3/orders/orderUuid
export async function getOrderDetails(uuid: string) {
  const URL = `${MUSEMENT_URL.orders}/${uuid}`;
  const response = await Calls.get(URL);
  return response;
}

/**
 * Cancel reservation for anitem
 * @param uuid order's uuid
 * @param itemUUID item's uuid
 * @returns
 */
//https://sandbox.Calls.com/api/v3/orders/orderUuid
export async function cancelOrderOfItem(
  orderUuid: string,
  orderItemUuid: string,
): Promise<void> {
  const URL = `${MUSEMENT_URL.orders}/${orderUuid}/items/${orderItemUuid}`;
  await Calls.delete(URL);
}

interface ILogPurchasePayload {
  name?: string;
  price: number;
  external_id: string;
  order_id: string;
}
export async function logPurchase(payload: ILogPurchasePayload) {
  await axios.post('http://expenture.travel/web/log-purchase', payload);
}
interface ILogPurchaseInBridgify {
  name?: string;
  price: number;
  source: 'Musement' | 'GoogleMaps';
  external_id: string;
  order_id: string;
  trip_id: string | null;
}
export async function logPurchaseInBridgify(payload: ILogPurchaseInBridgify) {
  await axios.post('http://expenture.travel/web/log-bridgify-purchase', payload);
}
