import React from 'react';
import styled from 'styled-components';
import CancellationPolicy from '../CancellationPolicy';
import { ActivityProperty } from '../../subcomponents/Shared';

enum MUSEMENT_COMP3 {
  ReducedPrice,
  When,
  Where,
  MeetingPoint,
  BookingFee,
  CancellationPolicy,
}

interface IDetail {
  title: string | null;
  content: JSX.Element | null;
}

interface IProps {
  activity: IActivity_Converted;
}

export default function PartThree({ activity }: IProps) {
  const {
    reducedPrice_HTML,
    when_HTML,
    meetingPoint,
    uuid,
    where_HTML,
    bookingFee = false,
  } = activity;

  const Component3Dic: { [key in MUSEMENT_COMP3]: IDetail } = {
    [MUSEMENT_COMP3.ReducedPrice]: {
      title: 'Reduced Price',
      content: reducedPrice_HTML ? (
        <div dangerouslySetInnerHTML={{ __html: reducedPrice_HTML }} />
      ) : null,
    },
    [MUSEMENT_COMP3.When]: {
      title: 'When',
      content: when_HTML ? <div dangerouslySetInnerHTML={{ __html: when_HTML }} /> : null,
    },
    [MUSEMENT_COMP3.Where]: {
      title: 'Where',
      content: where_HTML ? (
        <div dangerouslySetInnerHTML={{ __html: where_HTML }} />
      ) : null,
    },
    [MUSEMENT_COMP3.MeetingPoint]: {
      title: meetingPoint ? 'Meeting Point' : null,
      content: (
        <React.Fragment>
          {meetingPoint && <div dangerouslySetInnerHTML={{ __html: meetingPoint }} />}
          {/* {lat && lng ? (
            <MapContainerDiv>
              <Map location={location} />
            </MapContainerDiv>
          ) : null} */}
        </React.Fragment>
      ),
    },
    [MUSEMENT_COMP3.BookingFee]: {
      title: 'Booking Fee',
      content: bookingFee ? (
        <React.Fragment>
          We know. Nobody likes extra fees. <br />
          But not only do they cover the cost of managing your booking, they let us give
          you 24/7 customer service and a free app to access your tickets and discover
          more city sights.
        </React.Fragment>
      ) : (
        <React.Fragment>Good news! No extra fees are applied to this booking.</React.Fragment>
      ),
    },
    [MUSEMENT_COMP3.CancellationPolicy]: {
      title: 'Cancellation Policy',
      content: <CancellationPolicy uuid={uuid} />,
    },
  };

  return (
    <React.Fragment>
      {Array.from(Array(Object.keys(MUSEMENT_COMP3).length / 2).keys()).map(
        (key: MUSEMENT_COMP3) => {
          const { title, content } = Component3Dic[key];
          return content ? (
            <div key={title}>
              <ActivityProperty>
                {title && <Title>{title}</Title>}
                <Content>{content}</Content>
              </ActivityProperty>
              <SeperationLine />
            </div>
          ) : null;
        },
      )}
    </React.Fragment>
  );
}

const SeperationLine = styled.hr`
  width: 100%;
  border-top: 1px solid lightgrey;
  margin: 0.8rem 0;
`;
const Title = styled.div`
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 0.3rem;
`;
const Content = styled.div`
  // font-weight: 700;
  font-size: 0.9rem;
  margin-bottom: 1rem;
`;
