import React from 'react';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import GTranslateIcon from '@material-ui/icons/GTranslate';
import TodayIcon from '@material-ui/icons/Today';
import styled from 'styled-components';
import UpdateIcon from '@material-ui/icons/Update';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';

enum MUSEMENT_COMP1 {
  FreeCancellation,
  Languages,
  Availability,
  ConfirmationType,
}

interface IDetail {
  title: string;
  content: JSX.Element | null;
  icon: any;
}

interface IProps {
  activity: IActivity_Converted;
}

export default function PartOne({ activity }: IProps) {
  const { languages, availability, free_cancellation, requiresConfirmation } = activity;

  const RequiresPending = (
    <RequiresPendingContainer>
      Booking requires operator's confirmation
    </RequiresPendingContainer>
  );

  const Component1Dic: { [key in MUSEMENT_COMP1]: IDetail } = {
    [MUSEMENT_COMP1.FreeCancellation]: {
      title: 'Free Cancellation',
      content: free_cancellation ? (
        <FreeCancellation>Free Cancellation</FreeCancellation>
      ) : null,
      icon: StyledFreeCancellationIcon,
    },
    [MUSEMENT_COMP1.Languages]: {
      title: 'When',
      content:
        languages?.length > 0 ? (
          <div>
            <b>Languages:</b> {languages.join(', ')}
          </div>
        ) : null,
      icon: StyledLanguagesIcon,
    },
    [MUSEMENT_COMP1.Availability]: {
      title: 'Where',
      content: availability ? (
        <div>
          <b>Availability:</b> {availability}
        </div>
      ) : null,
      icon: StyledAvailabilityIcon,
    },
    [MUSEMENT_COMP1.ConfirmationType]: {
      title: 'Confirmation Time',
      content: !requiresConfirmation ? (
        <InstantBookingLabel>Instant Booking!</InstantBookingLabel>
      ) : (
        RequiresPending
      ),
      icon: !requiresConfirmation ? StyledEventAvailableIcon : StyledUpdateIcon,
    },
  };
  return (
    <React.Fragment>
      {Array.from(Array(Object.keys(MUSEMENT_COMP1).length / 2).keys()).map(
        (key: MUSEMENT_COMP1) => {
          const { icon: Icon, content } = Component1Dic[key];
          return content ? (
            <div key={'partone' + key}>
              <SeperationLine />
              <StyledActivityProperty>
                {Icon && <Icon />}
                {content}
              </StyledActivityProperty>
            </div>
          ) : null;
        },
      )}
    </React.Fragment>
  );
}
const SeperationLine = styled.hr`
  width: 100%;
  border-top: 1px solid lightgrey;
  margin: 0.8rem 0;
`;

const StyledActivityProperty = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
`;
const StyledLanguagesIcon = styled(GTranslateIcon)`
  margin-right: 1vh;
  color: rgb(9, 9, 77);
`;
const StyledFreeCancellationIcon = styled(CheckCircleRoundedIcon)`
  margin-right: 1vh;
  color: green;
`;
const StyledAvailabilityIcon = styled(TodayIcon)`
  margin-right: 1vh;
`;
const StyledUpdateIcon = styled(UpdateIcon)`
  margin-right: 1vh;
  color: orange;
`;
const StyledEventAvailableIcon = styled(EventAvailableIcon)`
  margin-right: 1vh;
`;

const FreeCancellation = styled.div`
  font-weight: 700;
  font-size: 1rem;
  color: forestgreen;
`;
const InstantBookingLabel = styled.div`
  font-weight: 500;
  font-size: 0.9rem;
  color: #0f740f;
`;

const RequiresPendingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
